import { gql } from '@apollo/client'

const INSERT_WAITLIST_USERS_PLATFORMS = gql`
  mutation InsertWaitlistUsersPlatforms(
    $usersPlatform: [WaitlistUsersPlatformsInsertInput!]!
  ) {
    insertWaitlistUsersPlatforms(objects: $usersPlatform) {
      affectedRows
    }
  }
`

export default INSERT_WAITLIST_USERS_PLATFORMS
