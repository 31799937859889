import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import useBreakpoints from '@/hooks/useBreakpoints'
import Instagram from '@/icons/Instagram'
import Twitter from '@/icons/Twitter'
import colors from '@/styles/_colors.module.scss'
import keys from '@/translations/en-us.json'
import styles from './Footer.module.scss'

const Footer = () => {
  const { isLg, isMd, isSm } = useBreakpoints()

  const renderLinks = () => (
    <nav>
      <ul className={styles.pagesList}>
        <li className={styles.pagesItem}>
          <Link href="/privacy-policy">{keys.footer.privacyPolicy}</Link>
        </li>
        <li className={styles.pagesItem}>
          <Link href="/terms-conditions">{keys.footer.termsAndConditions}</Link>
        </li>
        <li className={styles.pagesItem}>
          <Link href="/contact">{keys.footer.contactUs}</Link>
        </li>
      </ul>
    </nav>
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.journey}>
          <Link href="/">
            <div className={styles.logo}>
              <Image
                alt={keys.common.appName}
                src="landing/logo/j-brand.svg"
                {...(isSm
                  ? { width: 16, height: 20 }
                  : { width: 20, height: 24 })}
              />
            </div>
          </Link>
          <span className={styles.copyright}>
            {keys.footer.copyright.replace(
              '[YEAR]',
              new Date().getFullYear().toString(),
            )}
          </span>
        </div>
        {isMd || (isLg && renderLinks())}
        <nav>
          <ul className={styles.socialList}>
            <li className={styles.socialItem}>
              <a
                aria-label={keys.common.social.instagram.name}
                href={keys.common.social.instagram.url}
                target="_blank">
                <Instagram color={colors.colorLavender} />
              </a>
            </li>
            <li className={styles.socialItem}>
              <a
                aria-label={keys.common.social.twitter.name}
                href={keys.common.social.twitter.url}
                target="_blank">
                <Twitter color={colors.colorLavender} />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {isSm && renderLinks()}
    </footer>
  )
}

export default Footer
