import { useEffect, useState } from 'react'

interface TWindowSize {
  width: number | undefined
  height: number | undefined
}

const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState({
    isSm: false,
    isMd: false,
    isLg: false,
  })
  const [windowSize, setWindowSize] = useState<TWindowSize>({
    width: undefined,
    height: undefined,
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    if (windowSize?.width && windowSize?.width > 0 && windowSize?.width < 768) {
      setBreakpoints({ isSm: true, isMd: false, isLg: false })
    }

    if (
      windowSize?.width &&
      windowSize?.width >= 768 &&
      windowSize?.width < 992
    ) {
      setBreakpoints({ isSm: false, isMd: true, isLg: false })
    }

    if (windowSize?.width && windowSize?.width >= 992) {
      setBreakpoints({ isSm: false, isMd: false, isLg: true })
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])

  return breakpoints
}

export default useBreakpoints
