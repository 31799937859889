import React from 'react'
import Button from '@/components/Landing/Button'
import keys from '@/translations/en-us.json'
import styles from '../WaitlistForm.module.scss'

interface TEmailStep {
  email: string
  isEmailErrored: boolean
  isErrored: boolean
  isLoading: boolean
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onEmailSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const EmailStep = ({
  email,
  isEmailErrored,
  isErrored,
  isLoading,
  onEmailChange,
  onEmailSubmit,
}: TEmailStep) => (
  <form
    className={styles.form}
    onSubmit={onEmailSubmit}>
    <div className={styles.formInputGroup}>
      <input
        className={styles.formInput}
        onChange={onEmailChange}
        placeholder={keys.waitlist.emailStep.input}
        type="email"
        value={email}
      />
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        type="submit">
        {keys.waitlist.submit}
      </Button>
    </div>
    {isEmailErrored && !isErrored && (
      <p className={styles.error}>{keys.common.form.emailError}</p>
    )}
    {isErrored && !isEmailErrored && (
      <p className={styles.error}>{keys.common.form.genericError}</p>
    )}
  </form>
)

export default EmailStep
