import React, { useEffect, useId, useState } from 'react'
import { useMutation } from '@apollo/client'
import posthog from 'posthog-js'
import INSERT_WAITLIST_USER_ONE from '@/graphql/mutations/insertWaitlistUserOne'
import INSERT_WAITLIST_USERS_PLATFORMS from '@/graphql/mutations/insertWaitlistUsersPlatforms'
import isEmailValid from '@/utils/isEmailValid'
import EmailStep from './EmailStep'
import PlatformStep from './PlatformStep'
import SuccessStep from './SuccessStep'
import styles from './WaitlistForm.module.scss'

const WaitlistForm = () => {
  const [step, setStep] = useState<'email' | 'platform' | 'success'>('email')
  const [email, setEmail] = useState<string>('')
  const [platforms, setPlatforms] = useState<object>({
    ios: false,
    macos: false,
    android: false,
    web: false,
  })
  const [waitlistUserId, setWaitlistUserId] = useState<string>('')
  const [isErrored, setIsErrored] = useState<boolean>(false)
  const [isEmailErrored, setIsEmailErrored] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const id = useId()

  const [insertWaitlistUserOne, { data: emailData, error: emailError }] =
    useMutation(INSERT_WAITLIST_USER_ONE)

  const [
    insertWaitlistUsersPlatforms,
    { data: platformsData, error: platformsError },
  ] = useMutation(INSERT_WAITLIST_USERS_PLATFORMS)

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)

  const onPlatformChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target

    setPlatforms(prevState => ({ ...prevState, [name]: checked }))
  }

  const onEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const isEmailEmpty = email.length === 0

    if (isEmailEmpty || !isEmailValid(email)) {
      setIsEmailErrored(true)

      return
    }

    setIsLoading(true)
    setIsErrored(false)
    setIsEmailErrored(false)
    insertWaitlistUserOne({ variables: { email } })
    posthog.identify(id, { email })
  }

  const onPlatformSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)
    setIsErrored(false)

    const selectedPlatforms = Object.entries(platforms)
      .filter(platform => platform[1])
      .map(platform => ({
        waitlistPlatformName: platform[0].toUpperCase(),
        waitlistUserId,
      }))

    insertWaitlistUsersPlatforms({
      variables: {
        usersPlatform: selectedPlatforms,
      },
    })
  }

  // Email was subscribed successfully, got to platform step
  useEffect(() => {
    if (emailData) {
      setIsLoading(false)
      setIsErrored(false)
      setWaitlistUserId(emailData?.insertWaitlistUserOne?.id)
      setStep('platform')
    }
  }, [emailData])

  // 1. Email couldn't be subscribed because it already exists, go to success step
  // 2. Platforms saved successfully, go to success step
  // 3. Platforms couldn't be saved, skip to success step
  useEffect(() => {
    if (emailError ?? platformsData ?? platformsError) {
      setIsErrored(false)
      setIsLoading(false)
      setStep('success')
    }
  }, [emailError, platformsData, platformsError])

  const renderStep = () => {
    switch (step) {
      case 'email':
        return (
          <EmailStep
            email={email}
            isEmailErrored={isEmailErrored}
            isErrored={isErrored}
            isLoading={isLoading}
            onEmailChange={onEmailChange}
            onEmailSubmit={onEmailSubmit}
          />
        )
      case 'platform':
        return (
          <PlatformStep
            isErrored={isErrored}
            isLoading={isLoading}
            onPlatformChange={onPlatformChange}
            onPlatformSubmit={onPlatformSubmit}
          />
        )
      case 'success':
        return <SuccessStep />
    }
  }

  return <div className={styles.waitlistWrapper}>{renderStep()}</div>
}

export default WaitlistForm
