import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import MoonLoader from 'react-spinners/MoonLoader'
import colors from '@/styles/_colors.module.scss'
import styles from './Button.module.scss'

interface TButton {
  children: string
  className?: string
  href?: string
  isDisabled?: boolean
  isLoading?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Button = ({
  children,
  className,
  href,
  isDisabled,
  isLoading,
  type = 'button',
}: TButton) => {
  const customClassName = classNames(styles.button, {
    [className!]: !!className,
  })

  if (href) {
    return (
      <Link
        className={customClassName}
        href={href}>
        {children}
      </Link>
    )
  }

  return (
    <button
      className={customClassName}
      disabled={!!isDisabled}
      type={type}>
      {children}
      {isLoading && (
        <span className={styles.loading}>
          <MoonLoader
            color={colors.colorNeutral50}
            loading
            size={16}
            speedMultiplier={0.8}
          />
        </span>
      )}
    </button>
  )
}

export default Button
