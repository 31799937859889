import React from 'react'
import classNames from 'classnames'
import Button from '@/components/Landing/Button'
import keys from '@/translations/en-us.json'
import styles from '../WaitlistForm.module.scss'

interface TPlatformStep {
  isErrored: boolean
  isLoading: boolean
  onPlatformChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPlatformSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const PlatformStep = ({
  isErrored,
  isLoading,
  onPlatformChange,
  onPlatformSubmit,
}: TPlatformStep) => (
  <>
    <h4 className={styles.title}>{keys.waitlist.platformStep.title}</h4>
    <p className={styles.description}>
      {keys.waitlist.platformStep.description}
    </p>
    <form
      className={styles.form}
      onSubmit={onPlatformSubmit}>
      <div className={styles.formPlatformGroup}>
        <div className={styles.formPlatform}>
          <input
            id="ios"
            name="ios"
            onChange={onPlatformChange}
            type="checkbox"
          />
          <label htmlFor="ios">
            {keys.waitlist.platformStep.platforms.ios}
          </label>
        </div>
        <div className={styles.formPlatform}>
          <input
            id="macos"
            name="macos"
            onChange={onPlatformChange}
            type="checkbox"
          />
          <label htmlFor="macos">
            {keys.waitlist.platformStep.platforms.macos}
          </label>
        </div>
        <div className={styles.formPlatform}>
          <input
            id="android"
            name="android"
            onChange={onPlatformChange}
            type="checkbox"
          />
          <label htmlFor="android">
            {keys.waitlist.platformStep.platforms.android}
          </label>
        </div>
        <div className={styles.formPlatform}>
          <input
            id="web"
            name="web"
            onChange={onPlatformChange}
            type="checkbox"
          />
          <label htmlFor="web">
            {keys.waitlist.platformStep.platforms.web}
          </label>
        </div>
      </div>
      <Button
        isDisabled={isLoading}
        isLoading={isLoading}
        type="submit">
        {keys.waitlist.submit}
      </Button>
      {isErrored && (
        <p className={classNames(styles.error, styles.center)}>
          {keys.common.form.genericError}
        </p>
      )}
    </form>
  </>
)

export default PlatformStep
