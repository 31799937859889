import React from 'react'
import colors from '@/styles/_colors.module.scss'

interface TInstagram {
  color?: string
}

const Instagram = ({ color = colors.colorNeutral50 }: TInstagram) => (
  <svg
    fill="none"
    height="24"
    width="25"
    xmlns="http://www.w3.org/2000/svg">
    <g
      clipPath="url(#instagram)"
      fill={color}>
      <path d="M12.375 2.722c3.118 0 3.485.01 4.717.067 1.139.048 1.758.235 2.168.389a3.65 3.65 0 0 1 1.342.844c.41.399.663.773.871 1.301.159.399.352.999.401 2.103.055 1.195.07 1.55.07 4.574s-.01 3.38-.07 4.574c-.05 1.104-.242 1.704-.4 2.103a3.519 3.519 0 0 1-.872 1.3c-.411.399-.797.644-1.342.845-.41.154-1.03.341-2.168.39-1.232.052-1.598.066-4.717.066-3.119 0-3.485-.01-4.717-.067-1.139-.048-1.758-.235-2.168-.389a3.65 3.65 0 0 1-1.342-.844 3.439 3.439 0 0 1-.871-1.301c-.159-.399-.352-.999-.401-2.103-.06-1.195-.07-1.55-.07-4.574s.01-3.38.07-4.574c.05-1.104.242-1.704.4-2.103.214-.528.466-.907.872-1.3.41-.399.797-.644 1.342-.845.41-.154 1.03-.341 2.168-.39 1.232-.057 1.598-.066 4.717-.066Zm0-2.04c-3.168 0-3.569.014-4.811.067-1.243.053-2.09.245-2.832.528a5.696 5.696 0 0 0-2.069 1.305 5.512 5.512 0 0 0-1.346 2.002c-.287.72-.485 1.54-.545 2.746C.718 8.54.702 8.928.702 12s.016 3.46.07 4.666c.055 1.204.253 2.025.545 2.745a5.528 5.528 0 0 0 1.346 2.007 5.737 5.737 0 0 0 2.07 1.305c.742.279 1.588.47 2.83.528 1.243.053 1.644.067 4.812.067s3.569-.014 4.811-.067c1.243-.053 2.09-.245 2.832-.528a5.738 5.738 0 0 0 2.069-1.305 5.529 5.529 0 0 0 1.346-2.007c.287-.72.485-1.54.545-2.745.054-1.205.07-1.594.07-4.666s-.016-3.46-.07-4.666c-.055-1.204-.253-2.025-.545-2.745a5.529 5.529 0 0 0-1.346-2.007 5.72 5.72 0 0 0-2.064-1.305c-.743-.279-1.59-.47-2.832-.528-1.247-.053-1.648-.067-4.816-.067Z" />
      <path d="M12.375 6.187C9.063 6.187 6.381 8.79 6.381 12s2.682 5.813 5.994 5.813S18.369 15.21 18.369 12s-2.682-5.813-5.994-5.813Zm0 9.586c-2.148 0-3.89-1.69-3.89-3.773s1.742-3.773 3.89-3.773 3.89 1.69 3.89 3.773-1.742 3.773-3.89 3.773ZM18.607 7.315c.774 0 1.4-.608 1.4-1.358 0-.75-.626-1.359-1.4-1.359-.774 0-1.4.609-1.4 1.359s.626 1.358 1.4 1.358Z" />
    </g>
    <defs>
      <clipPath id="instagram">
        <path
          d="M0 0h24.75v24H0z"
          fill="#ffffff"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Instagram
