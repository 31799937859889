import { gql } from '@apollo/client'

const INSERT_WAITLIST_USER_ONE = gql`
  mutation InsertWaitlistUserOne($email: String!) {
    insertWaitlistUserOne(object: { email: $email }) {
      id
    }
  }
`

export default INSERT_WAITLIST_USER_ONE
