import React from 'react'
import Image from 'next/image'
import keys from '@/translations/en-us.json'
import styles from './DownloadApp.module.scss'

const DownloadApp = () => (
  <section className={styles.downloadApp}>
    <a
      href="https://apps.apple.com/us/app/journey-navigation-tools/id1662059644"
      rel="noopener noreferrer"
      target="_blank">
      <Image
        alt={keys.download.iosImageAlt}
        height={40}
        src="landing/assets/download-ios.svg"
        width={119.66}
      />
    </a>
    <a
      className={styles.macOS}
      href="https://apps.apple.com/us/app/journey-navigation-tools/id1662059644"
      rel="noopener noreferrer"
      target="_blank">
      <Image
        alt={keys.download.macosImageAlt}
        height={40}
        src="landing/assets/download-macos.svg"
        width={156.09}
      />
    </a>
  </section>
)

export default DownloadApp
