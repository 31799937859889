import React from 'react'
import colors from '@/styles/_colors.module.scss'

interface TTwitter {
  color?: string
}

const Twitter = ({ color = colors.colorNeutral50 }: TTwitter) => (
  <svg
    fill="none"
    height="24"
    width="25"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#twitter)">
      <path
        d="M8.435 21.456c8.779 0 13.579-7.277 13.579-13.584 0-.206-.005-.413-.014-.62a9.638 9.638 0 0 0 2.38-2.471 9.698 9.698 0 0 1-2.74.753 4.787 4.787 0 0 0 2.097-2.64 9.564 9.564 0 0 1-3.029 1.162 4.77 4.77 0 0 0-3.484-1.507 4.775 4.775 0 0 0-4.652 5.86 13.564 13.564 0 0 1-9.835-4.987 4.772 4.772 0 0 0 1.478 6.374 4.762 4.762 0 0 1-2.16-.594v.062a4.774 4.774 0 0 0 3.831 4.68 4.733 4.733 0 0 1-2.155.082 4.783 4.783 0 0 0 4.459 3.316 9.565 9.565 0 0 1-5.928 2.045c-.384 0-.763-.024-1.138-.067a13.551 13.551 0 0 0 7.31 2.136Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="twitter">
        <path
          d="M.75 0h24v24h-24z"
          fill="#ffffff"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Twitter
