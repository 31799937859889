import React, { useState } from 'react'
import DownloadApp from '@/components/Landing/DownloadApp'
import WaitlistForm from '@/components/Landing/WaitlistForm'
import keys from '@/translations/en-us.json'
import styles from './DownloadSection.module.scss'

const DownloadSection = () => {
  const [isWaitlistExpanded, setIsWaitlistExpanded] = useState<boolean>(false)

  const onWaitlistExpandClick = () => setIsWaitlistExpanded(true)

  return (
    <section className={styles.waitlist}>
      <h2 className={styles.title}>{keys.waitlist.title}</h2>
      <p className={styles.description}>{keys.waitlist.description}</p>
      <div className={styles.downloadApp}>
        <DownloadApp />
      </div>
      {isWaitlistExpanded ? (
        <WaitlistForm />
      ) : (
        <button
          className={styles.expandButton}
          onClick={onWaitlistExpandClick}
          type="button">
          {keys.hero.requestAccessButton}
        </button>
      )}
    </section>
  )
}

export default DownloadSection
