import React from 'react'
import keys from '@/translations/en-us.json'
import styles from '../WaitlistForm.module.scss'

const SuccessStep = () => (
  <>
    <h4 className={styles.title}>{keys.waitlist.successStep.title}</h4>
    <p className={styles.description}>
      {keys.waitlist.successStep.description}
    </p>
  </>
)

export default SuccessStep
