import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import Instagram from '@/icons/Instagram'
import Twitter from '@/icons/Twitter'
import keys from '@/translations/en-us.json'
import styles from './Header.module.scss'

const Header = () => (
  <header className={styles.header}>
    <Link href="/">
      <Image
        alt={keys.common.appName}
        height={48}
        src="landing/logo/journey.svg"
        width={163}
      />
    </Link>
    <nav>
      <ul className={styles.social}>
        <li>
          <a
            aria-label={keys.common.social.instagram.name}
            href={keys.common.social.instagram.url}
            target="_blank">
            <Instagram />
          </a>
        </li>
        <li>
          <a
            aria-label={keys.common.social.twitter.name}
            href={keys.common.social.twitter.url}
            target="_blank">
            <Twitter />
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
